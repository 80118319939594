export const getTopicStatus = statusId => {
  const statuses = ['All', 'Joined', 'Approved', 'WaitApproval', 'NotJoin', 'Rejected']

  return statuses[statusId]
}

export const getTopicStatusColor = statusId => {
  const colors = ['', 'info', 'success', 'success', 'warning', 'error']

  return colors[statusId]
}

export const getTopicBackgroundColor = statusId => {
  const backgroundColors = [
    'rgba(0,0,0,0.12)',
    'rgba(22,177,255, 0.12)',
    'rgba(86,202,0, 0.12)',
    'rgba(86,202,0, 0.12)',
    'rgba(255,180,0, 0.12)',
    'rgba(255,76,81, 0.12)',
  ]

  return backgroundColors[statusId]
}

export const getStatusParams = statusId => {
  switch (statusId) {
    case 1:
      return {
        is_joined: 1,
      }
    case 2:
      return {
        is_approved: 1,
      }
    case 3:
      return {
        is_approved: 0,
      }
    case 4:
      return {
        is_joined: 0,
      }
    case 5:
      return {
        is_approved: 2,
      }
    case 0:
    default:
      return {}
  }
}

export const enrichTopicTime = (start, end) => {
  if (start.substring(0, 10) === end.substring(0, 10)) {
    const time = `${start.substring(0, 10)} ${start.substring(11)} - ${end.substring(11)}`

    return time
  }

  return `${start} - ${end}`
}

const skillShareHelpers = {
  getTopicStatus,
  getTopicStatusColor,
  getTopicBackgroundColor,
  getStatusParams,
  enrichTopicTime,
}

export default skillShareHelpers
