<!-- eslint-disable vue/valid-v-on -->
<template>
  <div>
    <v-card>
      <div class="d-flex align-center justify-space-between">
        <v-card-title style="line-height: 1.45">
          {{ topicData.name }}
        </v-card-title>
        <div class="mr-4">
          <v-chip
            :color="getTopicStatusColor(topicData.status_label)"
            small
            :class="`v-chip-light-bg ${getTopicStatusColor(topicData.status_label)}--text`"
          >
            {{ $t(getTopicStatus(topicData.status_label)) }}
          </v-chip>
        </div>
      </div>
      <v-card-text v-if="isOwner">
        <v-btn
          small
          outlined
          class="v-chip-light-bg primary--text"
          @click="showQrCode = true"
        >
          {{ $t('ViewQRCode') }}
        </v-btn>
      </v-card-text>

      <v-card-text
        v-if="isOwner && topicData.status_label === 3"
        class="warning--text"
      >
        {{ $t('WaitStaffApproveMsg') }}
      </v-card-text>

      <v-card-text>
        <strong>{{ $t('Speaker') }}</strong>: {{ topicData.speaker }}
      </v-card-text>
      <v-card-text>
        <strong>{{ $t('Time') }}</strong>: {{
          enrichTopicTime(
            formatTimestampToDatetime(topicData.start_time, 'DD/MM/YYYY HH:mm'),
            formatTimestampToDatetime(topicData.end_time, 'DD/MM/YYYY HH:mm')
          )
        }}
      </v-card-text>
      <v-card-text v-if="!!topicData.image_1_url || !!topicData.image_2_url">
        <div class="d-flex justify-space-between mb-4">
          <v-img
            v-if="!!topicData.image_1_url"
            :style="!!topicData.image_2_url ? 'width: 40%; margin-right: 8px' : 'width: 100%'"
            :src="topicData.image_1_url"
            :alt="`image ${topicData.image_1}`"
          />
          <v-img
            v-if="!!topicData.image_2_url"
            :style="!!topicData.image_1_url ? 'width: 40%; margin-left: 8px' : 'width: 100%'"
            :src="topicData.image_2_url"
            :alt="`image ${topicData.image_2_url}`"
          />
        </div>
      </v-card-text>
      <v-card-text>
        <p style="white-space: pre-line">
          <strong>{{ $t('Description') }}</strong>: <br /> {{ topicData.description }}
        </p>
      </v-card-text>
      <v-card-text>
        <strong>{{ $t('Participants') }}</strong>: {{ topicData.participants }} / {{ topicData.max_seat }}
      </v-card-text>
      <v-card-text>
        <template>
          <div>
            <div class="mb-4">
              <div
                v-for="(survey) in surveyData"
                :key="survey.id_question"
                class="mb-4"
              >
                <div class="mb-4 font-weight-bold">
                  {{ survey.title }}
                </div>
                <div>
                  <p>(Vui lòng kéo chọn số người tham dự. Tối đa 01 lượt được 02 đăng ký cho 02 người)</p>
                </div>
                <template
                  v-if="survey.id_question === 1"
                >
                  <v-slider
                    v-model="questionOne"
                    thumb-label="always"
                    min="1"
                    max="2"
                    class="mt-10 mb-4"
                    hide-details
                  >
                  </v-slider>
                </template>
                <template
                  v-for="(option,i) in survey.options"
                >
                  <div
                    v-if="survey.id_question === 1"
                    :key="`option-${survey.id_question}-${option.id_option}`"
                  >
                    <v-text-field
                      v-if="i < questionOne"
                      v-model="optionText[survey.id_question+'-'+option.id_option]"
                      :label="option.content"
                      outlined
                      dense
                      hide-details
                      class="mb-4"
                      type="number"
                      @input="
                        (event) => handleAnswer(survey.id_question, option.id_option, survey.type, option.type, event)
                      "
                    >
                    </v-text-field>
                  </div>
                  <div
                    v-else-if="survey.type === 0"
                    :key="`option-${survey.id_question}-${option.id_option}`"
                    class="my-2"
                  >
                    <v-radio-group
                      v-model="tmpAnswer[survey.id_question]"
                      hide-details
                      dense
                    >
                      <v-radio
                        :value="option.id_option"
                        :label="option.content"
                        class="shrink me-1 mt-0"
                        :hide-details="true"
                        dense
                        @change="
                          (event) => handleAnswer(survey.id_question, option.id_option, survey.type, option.type, event)
                        "
                      >
                        <template v-slot:label>
                          <span
                            v-if="option.type===0"
                            class="body-2"
                          >
                            {{ option.content }}
                          </span>
                          <v-text-field
                            v-if="option.type===1"
                            v-model="optionText[survey.id_question+'-'+option.id_option]"
                            :label="option.content"
                            outlined
                            dense
                            hide-details
                            @input="
                              (event) => handleAnswer(survey.id_question, option.id_option, survey.type, option.type, event)
                            "
                          >
                          </v-text-field>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </div>
                  <div
                    v-else
                    :key="`option-${survey.id_question}-${option.id_option}`"
                    class="my-2"
                  >
                    <v-checkbox
                      :value="option.id_option"
                      :label="option.content"
                      class="shrink me-1 mt-0"
                      hide-details
                      @change="
                        (event) => handleAnswer(survey.id_question, option.id_option, survey.type, option.type, event)
                      "
                    >
                      <template v-slot:label>
                        <span
                          v-if="option.type===0"
                          class="body-2"
                        >
                          {{ option.content }}
                        </span>
                        <v-text-field
                          v-if="option.type===1"
                          v-model="optionText[survey.id_question+'-'+option.id_option]"
                          :label="option.content"
                          outlined
                          dense
                          hide-details
                          @input="
                            (event) => handleAnswer(survey.id_question, option.id_option, survey.type, option.type, event)
                          "
                        >
                        </v-text-field>
                      </template>
                    </v-checkbox>
                  </div>
                </template>
                <div
                  v-if="haveError(survey.id_question)"
                  class="my-4 font-weight-bold error--text"
                >
                  {{ $t('Bạn vui lòng chọn một câu trả lời') }}.
                </div>
              </div>
            </div>
          </div>
        </template>
      </v-card-text>
      <v-btn
        v-if="isOwner && topicData.status_label === 2"
        color="success"
        plain
        class="mb-4"
        @click="handleShowCheckinList"
      >
        {{ $t('CheckinList') }}
      </v-btn>

      <v-card-text>
        <v-row>
          <v-col
            class="pb-0"
            cols="12"
            md="6"
          >
            <v-btn
              v-if="isOwner && topicData.is_approved"
              color="error"
              outlined
              block
              :loading="loading"
              @click="isShowDialogCancelTopic=true"
            >
              {{ $t('CancelSharing') }}
            </v-btn>
            <v-btn
              v-if="isOwner && !topicData.is_approved"
              color="error"
              outlined
              block
              :loading="loading"
              @click="isShowDialogDeleteTopic=true"
            >
              {{ $t('DeleteSharing') }}
            </v-btn>
            <v-btn
              v-else-if="isNotJoined"
              color="primary"
              block
              :loading="loading"
              @click="isShowDialogJoinTopic=true"
            >
              {{ $t('Join') }}
            </v-btn>
            <v-btn
              v-else-if="isJoined"
              color="error"
              block
              :loading="loading"
              @click="isShowDialogLeaveTopic=true"
            >
              {{ $t('Leave') }}
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-btn
              color="warning"
              outlined
              block
              @click="$router.back()"
            >
              {{ $t('Back') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="showCheckedinList"
      max-width="500px"
    >
      <v-card class="triangle-card mt-4 ">
        <v-card-title class="page-title">
          {{ $t('CheckinList') }}
        </v-card-title>
        <v-card-text v-if="!!topicData.image_1_url || !!topicData.image_2_url">
          <div class="d-flex justify-space-between">
            <v-img
              v-if="!!topicData.image_1_url"
              :style="!!topicData.image_2_url ? 'width: 40%; margin-right: 8px' : 'width: 100%'"
              :src="topicData.image_1_url"
              :alt="`image ${topicData.image_1}`"
            />
            <v-img
              v-if="!!topicData.image_2_url"
              :style="!!topicData.image_1_url ? 'width: 40%; margin-left: 8px' : 'width: 100%'"
              :src="topicData.image_2_url"
              :alt="`image ${topicData.image_2_url}`"
            />
          </div>
        </v-card-text>
        <template>
          <v-list
            v-if="checkedInList.length > 0"
            two-line
          >
            <template v-for="(item, index) in checkedInList">
              <v-list-item
                :key="item.id_user"
              >
                <v-list-item-avatar>
                  <v-img :src="item.face_image_url"></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>
                    <span class="font-weight-semibold primary--text">
                      {{ item.full_name }}
                    </span>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <span class="text--primary ">
                      Checkin
                    </span>
                    {{ formatTimestampToDatetime(item.checkIn_at) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider
                v-if="index < checkedInList.length - 1"
                :key="`divider-${item.id_user}`"
                :inset="item.inset"
              ></v-divider>
            </template>
          </v-list>
          <div
            v-else
            class="text-center pb-2"
          >
            {{ $t("NoData") }}
          </div>
        </template>
        <v-card-actions>
          <v-row>
            <div class="v-col col-12">
              <v-btn
                block
                outlined
                @click="showCheckedinList = false"
              >
                {{ $t('Close') }}
              </v-btn>
            </div>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showQrCode"
      max-width="500px"
    >
      <v-card class="text-center">
        <v-card-text>
          <vue-qr
            class="section-to-print"
            :size="300"
            :text="!!topicData.id_topic && topicData.id_topic.toString()"
          ></vue-qr>
        </v-card-text>
        <v-card-title class="justify-center">
          {{ topicData.name }}
        </v-card-title>
        <v-card-text>
          {{ topicData.speaker }}
        </v-card-text>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-btn
                color="primary"
                block
                @click="handlePrint"
              >
                {{ $t('PrintQr') }}
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-btn
                block
                outlined
                @click="showQrCode = false"
              >
                {{ $t('Close') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <ConfirmDialog
      v-if="isShowDialogCancelTopic"
      :title="$t('AreYouSure')"
      :content="$t('AreYouSureToCancelTopic')"
      @cancel="isShowDialogCancelTopic = false"
      @ok="handleCancelTopic"
    ></ConfirmDialog>
    <ConfirmDialog
      v-if="isShowDialogDeleteTopic"
      :title="$t('AreYouSure')"
      :content="$t('AreYouSureToDeleteTopic')"
      @cancel="isShowDialogDeleteTopic = false"
      @ok="handleDeleteTopic"
    ></ConfirmDialog>
    <ConfirmDialog
      v-if="isShowDialogLeaveTopic"
      :title="$t('AreYouSure')"
      :content="$t('AreYouSureToLeaveTopic')"
      @cancel="isShowDialogLeaveTopic = false"
      @ok="handleLeaveTopic"
    ></ConfirmDialog>
    <ConfirmDialog
      v-if="isShowDialogJoinTopic"
      :title="$t('AreYouSure')"
      :content="$t('AreYouSureToJoinTopic')"
      @cancel="isShowDialogJoinTopic = false"
      @ok="handleJoinTopic"
    ></ConfirmDialog>
  </div>
</template>
<script>
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import SkillShareService from '@/services/SkillShareService'
import store from '@/store'
import { enrichTopicTime, getTopicStatus, getTopicStatusColor } from '@/utils/skillShareHelper'
import { useRouter } from '@core/utils'
import { computed, onMounted, ref } from '@vue/composition-api'
import VueQr from 'vue-qr'

export default {
  components: {
    VueQr,
    ConfirmDialog,
  },
  setup() {
    const { router } = useRouter()
    const topicId = router.currentRoute.params.id
    const topicData = ref({})
    const surveyData = ref([])
    const showCheckedinList = ref(false)
    const showQrCode = ref(false)
    const checkedInList = ref([])
    const userData = ref({ ...store.getters['auth/currentUser'] })
    const isOwner = computed(() => userData.value.id_user === topicData.value?.id_user_creator)
    const isJoined = computed(() => topicData.value.status_label === 1)
    const isNotJoined = computed(() => topicData.value.status_label === 4)
    const loading = ref(false)
    const isShowDialogCancelTopic = ref(false)
    const isShowDialogJoinTopic = ref(false)
    const isShowDialogLeaveTopic = ref(false)
    const isShowDialogDeleteTopic = ref(false)
    const surveyAnswer = ref([])
    const optionText = ref({})
    const surveyErrors = ref([])
    const tmpAnswer = ref({})
    const questionOne = ref(1)

    const getTopicData = async () => {
      try {
        const resp = await SkillShareService.getTopicDetail(topicId)
        topicData.value = resp.data.data.topic
        surveyData.value = resp.data.data.survey
        checkedInList.value = resp.data.data.joiners
      } catch {}
    }

    const haveError = function(id) {
      return !!surveyErrors.value.find(item => item === id)
    }

    onMounted(async () => {
      await getTopicData()
    })

    const handleJoinTopic = async () => {
      loading.value = true
      try {
        surveyErrors.value = []
        surveyData.value.forEach(s => {
          const ans = surveyAnswer.value.find(a => a.id_question === s.id_question)
          console.log(ans)
          if (!ans) {
            surveyErrors.value.push(s.id_question)
          }
        })

        if (surveyErrors.value.length > 0) {
          loading.value = false
          isShowDialogJoinTopic.value = false

          return
        }

        const answer = surveyAnswer.value.map(ans => {
          const fans = {}
          // eslint-disable-next-line no-restricted-syntax
          for (const key in ans) {
            if (ans[key] !== null) {
              fans[key] = ans[key]
            }
          }

          return fans
        })
        await SkillShareService.joinTopic(topicId, answer)
        await getTopicData()
        store.dispatch('auth/getUserInfo')
        store.commit('snackbar/show', {
          message: 'Join event successfully',
          type: 'success',
        })
        isShowDialogJoinTopic.value = false
      } catch {
      } finally {
        loading.value = false
      }
    }

    const handleAnswer = function(questionId, answerId, type, ansType, event) {
      console.log(questionId, answerId, type, ansType, event)

      // Radio
      if (type === 0) {
        const oldAnswer = surveyAnswer.value.findIndex(
          answer => answer.id_question === questionId,
        )
        if (oldAnswer > -1) {
          this.$set(surveyAnswer.value, oldAnswer, {
            id_question: questionId,
            id_option: answerId,
            text:
              ansType === 0 ? null : optionText.value[`${questionId}-${answerId}`] || null,
          })
        } else {
          surveyAnswer.value.push({
            id_question: questionId,
            id_option: answerId,
            text:
              ansType === 0 ? null : optionText.value[`${questionId}-${answerId}`] || null,
          })
        }
      } else {
        const oldAnswer = surveyAnswer.value.findIndex(
          answer => answer.id_question === questionId && answer.id_option === answerId,
        )
        if (oldAnswer < 0) {
          surveyAnswer.value.push({
            id_question: questionId,
            id_option: answerId,
            text:
              ansType === 0 ? null : optionText.value[`${questionId}-${answerId}`] || null,
          })
        } else if (event) {
          this.$set(surveyAnswer.value, oldAnswer, {
            id_question: questionId,
            id_option: answerId,
            text:
              ansType === 0 ? null : optionText.value[`${questionId}-${answerId}`] || null,
          })
        } else {
          surveyAnswer.value.splice(oldAnswer, 1)
        }
      }
    }

    const handleLeaveTopic = async () => {
      loading.value = true
      try {
        await SkillShareService.leaveTopic(topicId)
        await getTopicData()
        store.dispatch('auth/getUserInfo')
        store.commit('snackbar/show', {
          message: 'Leave event successfully',
          type: 'success',
        })
        isShowDialogLeaveTopic.value = false
      } catch {
      } finally {
        loading.value = false
      }
    }

    const handleCancelTopic = async () => {
      loading.value = true
      try {
        await SkillShareService.cancelTopic(topicId)
        router.push({ name: 'skill-share-list' })
        store.commit('snackbar/show', {
          message: 'Cancel event successfully',
          type: 'success',
        })
        isShowDialogCancelTopic.value = false
      } catch {
      } finally {
        loading.value = false
      }
    }
    const handleDeleteTopic = async () => {
      loading.value = true
      try {
        await SkillShareService.deleteTopic(topicId)
        router.push({ name: 'skill-share-list' })
        store.commit('snackbar/show', {
          message: 'Delete event successfully',
          type: 'success',
        })
        isShowDialogDeleteTopic.value = false
      } catch {
      } finally {
        loading.value = false
      }
    }

    const handleShowCheckinList = () => {
      showCheckedinList.value = true
      getTopicData()
    }

    const handlePrint = () => {
      try {
        // Print for Safari browser
        document.execCommand('print', false, null)
      } catch {
        window.print()
      }
    }

    return {
      loading,
      isOwner,
      isJoined,
      isNotJoined,
      showQrCode,
      showCheckedinList,

      topicData,
      surveyData,
      checkedInList,
      getTopicStatus,
      getTopicStatusColor,
      enrichTopicTime,
      handleJoinTopic,
      handleLeaveTopic,
      handleCancelTopic,
      handleShowCheckinList,
      handlePrint,
      handleDeleteTopic,
      isShowDialogCancelTopic,
      isShowDialogJoinTopic,
      isShowDialogLeaveTopic,
      isShowDialogDeleteTopic,
      surveyAnswer,
      handleAnswer,
      optionText,
      surveyErrors,
      haveError,
      tmpAnswer,
      questionOne,
    }
  },
}
</script>

<style lang="scss" scoped>
img {
  max-width: 100%;
  border: 1px solid #ccc;
  display: block !important;
  margin: 0 auto;
}
.section-to-print img {
  width: 100%;
}

@media print {
  body * {
    visibility: hidden !important;
  }
  .section-to-print,
  .section-to-print * {
    visibility: visible !important;
  }
}
</style>
