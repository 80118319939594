/* eslint-disable class-methods-use-this */
import axios from '@/plugins/axios'

class SkillShareService {
  getListTopic(params) {
    return axios.get('/v1/skillShare/topic', { params })
  }

  getListReservation(params) {
    return axios.get('/v1/skillShare/reservation', { params })
  }

  getTopicDetail(topicId) {
    return axios.get(`/v1/skillShare/topic/${topicId}?as_user=1`)
  }

  getStaffTopicDetail(topicId) {
    return axios.get(`/v1/skillShare/topic/${topicId}`)
  }

  createTopic(data) {
    return axios.post('/v1/skillShare/topic', data)
  }

  editTopic(topicId, data) {
    return axios.put(`/v1/skillShare/topic/${topicId}`, data)
  }

  cancelTopic(topicId) {
    return axios.put(`/v1/skillShare/topic/cancel/${topicId}`)
  }

  deleteTopic(topicId) {
    return axios.delete(`/v1/skillShare/topic/${topicId}`)
  }

  joinTopic(topicId, answers) {
    return axios.post(`/v1/skillShare/topic/guest/${topicId}`, { join: 1, answers })
  }

  leaveTopic(topicId) {
    return axios.post(`/v1/skillShare/topic/guest/${topicId}`, { join: 0 })
  }

  checkinTopic(topicId) {
    return axios.post(`/v1/skillShare/topic/checkIn/${topicId}`)
  }

  createReservation(data) {
    return axios.post('/v1/skillShare/reservation', data)
  }

  cancelReservation(reservationId) {
    return axios.delete(`/v1/skillShare/reservation/${reservationId}`)
  }

  approveTopic(topicId) {
    return axios.put(`/v1/skillShare/topic/${topicId}`, { is_approved: 1 })
  }

  rejectTopic(topicId) {
    return axios.put(`/v1/skillShare/topic/${topicId}`, { is_approved: 2 })
  }

  getQuestionList() {
    return axios.get('/v1/skillShare/survey/question')
  }
}

export default new SkillShareService()
